export const Colors = {
  Black: "#000000",
  TransparentBlack: "#000000e0",
  Transparent: "transparent",
  Blue: "#1051F5",
  Cyan: "#5AE9CF",
  Gray: "#969696",
  LightGray: "#D8D8D8",
  Purple: "8342A0",
  Yellow: "#FFD336",
  White: "#ffffff",
};
