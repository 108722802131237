import React, { FunctionComponent, useCallback, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../core/colors";
import { InfoPanelBase } from "./Base";

import LocationSVG from "../Icons/Location.svg";
import SpotSVG from "../Icons/Spot.svg";
import GoToPointerSVG from "../Icons/GoToPointer.svg";
import DottedLineSVG from "../Icons/DottedLine.svg";
import { useAppContext } from "../../core/contexts/AppContext";
import { useInfoPanelContext } from "../../core/contexts/InfoPanelContext";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import Geocode from "react-geocode";
import { useEffect } from "react";
import { useOpenGoogleMaps } from "../../core/hooks/useOpenGoogleMaps";

const Container = styled.div`
  padding: 5px 0 15px;
  margin: 0 10%;
  position: relative;
`;

const InputContainer = styled.div<{ marginBottom?: number }>`
  display: flex;

  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : ""}
`;

const Input = styled.input`
  border-radius: 15px;
  border: none;
  outline: 0;
  padding: 8px;
  text-align: center;
  width: 100%;
  background-color: ${Colors.LightGray};
`;

const NoLocation = styled.button`
  border-radius: 15px;
  border: none;
  padding: 4px 8px;
  text-align: center;
  width: 100%;
  background-color: ${Colors.LightGray};
  color: ${Colors.Black};
  cursor: pointer;
  height: 31.5px;
  text-overflow: ellipsis;
  line-height: 25px;
`;

const ButtonContainer = styled.div<{ marginBottom?: number }>`
  display: flex;
  justify-content: center;
`;

const LocationIcon = styled.img.attrs({ src: LocationSVG })`
  width: 25px;
  margin-right: 10px;
`;

const SpotIcon = styled.img.attrs({ src: SpotSVG })`
  width: 25px;
  margin-right: 10px;
`;

const GoToIcon = styled.img.attrs({ src: GoToPointerSVG })`
  width: 25px;
`;

const DottedLineIcon = styled.img.attrs({ src: DottedLineSVG })`
  height: 40px;
  position: absolute;
  top: 37px;
  left: 10px;
  width: 3px;
`;

export const GoToPanel: FunctionComponent = () => {
  const { myLocation, locations, setMyLocation } = useAppContext();
  const { currentLocation } = useInfoPanelContext();
  const openGoogleMaps = useOpenGoogleMaps();
  const { t } = useTranslation("common");

  const [isGettingLocation, setIsGettingLocation] = useState<boolean>(false);

  const [myLocationValue, setMyLocationValue] = useState<string>(
    myLocation?.address || ""
  );

  useEffect(() => {
    Geocode.setApiKey("AIzaSyCTofAmPMFgqWfYdRhFe2BCJ4i0yNCPCQY");
    Geocode.setLanguage("en");
    Geocode.setRegion("hr");
    Geocode.setLocationType("ROOFTOP");
  }, []);

  const errorCallback = useCallback(
    (error) => {
      switch (error.code) {
        case 1:
        case 2:
        case 3:
          toast.error(t("error.location." + error.code));
          return;
        default:
          toast.error(t("error.generic"));
      }
      setIsGettingLocation(false);
    },
    [t]
  );

  const getAddressFromCoordinates = useCallback(
    (lat: string, lng: string) => {
      Geocode.fromLatLng(lat, lng)
        .then(
          (response) => {
            if (!response?.results?.[0]?.address_components) {
              errorCallback({ code: 0 });
              return;
            }
            const addressComponents: any[] =
              response.results[0].address_components;

            const street = addressComponents.find((component) =>
              component.types.includes("route")
            ).long_name;
            const streetNumber = addressComponents.find((component) =>
              component.types.includes("street_number")
            ).long_name;
            const address = `${street} ${streetNumber}`;

            setMyLocation({
              address,
              location: {
                lat: Number(lat),
                lng: Number(lng),
              },
            });
            if (streetNumber) {
              setMyLocationValue(address);
            } else {
              setMyLocationValue(`${lat}, ${lng}`);
            }
          },
          (error) => {
            console.error(error);
            errorCallback({ code: 0 });
          }
        )
        .finally(() => {
          setIsGettingLocation(false);
        });
    },
    [errorCallback, setMyLocation]
  );

  const successCallback = useCallback(
    (position) => {
      getAddressFromCoordinates(
        position.coords.latitude,
        position.coords.longitude
      );
    },
    [getAddressFromCoordinates]
  );

  const getLocation = useCallback(() => {
    try {
      setIsGettingLocation(true);
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } catch {
      errorCallback({ code: 0 });
    }
  }, [errorCallback, successCallback]);

  return (
    <InfoPanelBase>
      <Container>
        <InputContainer marginBottom={40}>
          <SpotIcon />
          {!!myLocation?.address ? (
            <Input
              value={myLocationValue}
              onChange={(event) => setMyLocationValue(event.target.value)}
            />
          ) : (
            <NoLocation onClick={getLocation}>
              {isGettingLocation
                ? t("location.loading")
                : t("location.get-location")}
            </NoLocation>
          )}
        </InputContainer>

        <InputContainer marginBottom={25}>
          <LocationIcon />
          <Input
            disabled
            value={currentLocation?.address || locations?.[0]?.address || ""}
            onChange={() => {}}
          />
        </InputContainer>

        <ButtonContainer>
          <Button onClick={openGoogleMaps}>
            <GoToIcon /> {t("actions.GO")}!
          </Button>
        </ButtonContainer>

        <DottedLineIcon />
      </Container>
    </InfoPanelBase>
  );
};

GoToPanel.displayName = "GoToPanel";
