import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { useGalleryContext } from "../../../core/contexts/GalleryContext";
import { chunks, getImageUrl } from "../../../core/utils";
import { PageBodyImage, PageProps } from "../../../pages/types";

const Wrapper = styled.div``;

const ImageGallery = styled.div<{ isFullHeight: boolean }>`
  display: flex;
  flex-direction: ${({ isFullHeight }) => (isFullHeight ? "column" : "row")};
`;

const ImageGalleryImage = styled.img`
  height: 50px;
  width: 18%;
  object-fit: cover;
  cursor: pointer;
`;

const ImageGalleryFullImage = styled.img`
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
`;

const GalleryRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
`;

export interface InfoPanelGalleryProps {
  isFullHeight: boolean;
  location: PageProps;
  images: PageBodyImage[];
}

export const InfoPanelGallery: FunctionComponent<InfoPanelGalleryProps> = ({
  isFullHeight,
  location,
  images,
}) => {
  const { showGallery } = useGalleryContext();

  const handleShowGallery = (startImageIndex: number) => {
    showGallery(
      [
        {
          key: location.header,
          title: location.title,
          subtitle: location.subtitle,
        },
        ...images,
      ],
      startImageIndex
    );
  };

  const firstChunk = chunks(images, isFullHeight ? 5 : 4)[0];
  const secondChunk = chunks(images, isFullHeight ? 5 : 4).slice(1);

  return (
    <Wrapper>
      {location && isFullHeight && (
        <ImageGalleryFullImage
          src={getImageUrl(location.header)}
          alt={location.title}
          onClick={() => handleShowGallery(0)}
        />
      )}
      <ImageGallery isFullHeight={isFullHeight}>
        <GalleryRow>
          {!isFullHeight && (
            <ImageGalleryImage
              src={getImageUrl(location.header)}
              alt={location.title}
              onClick={() => handleShowGallery(0)}
            />
          )}
          {firstChunk.map((image, i) => (
            <ImageGalleryImage
              key={i}
              src={getImageUrl(image.key, 86)}
              alt={image.title}
              onClick={() => handleShowGallery(i + 1)}
            />
          ))}
        </GalleryRow>
        {isFullHeight &&
          secondChunk.map((chunk, i) => (
            <GalleryRow key={`row-${i}`}>
              {chunk.map((image, i) => (
                <ImageGalleryImage
                  key={i}
                  src={getImageUrl(image.key, 86)}
                  alt={image.title}
                  onClick={() => handleShowGallery(i + firstChunk.length + 1)}
                />
              ))}
            </GalleryRow>
          ))}
      </ImageGallery>
    </Wrapper>
  );
};

InfoPanelGallery.displayName = "InfoPanelGallery";

// const ImageGalleryImage = styled.img`
//   height: 50px;
//   width: 18%;
//   object-fit: cover;
//   cursor: pointer;

//   &.full-width {
//     width: 100%;
//     height: 100%;
//     margin-top: 10px;
//   }
// `;

// const Skeleton = styled.div`
//   height: 50px;
//   width: 18%;
//   object-fit: cover;
//   cursor: pointer;
//   background-color: ${Colors.Gray};

//   &.full-width {
//     height: 100%;
//     width: 100%;
//     margin-top: 10px;
//   }
// `;
// interface GalleryImageProps {
//   alt: string;
//   src: string;
//   onClick?: () => void;
//   fullWidth?: boolean;
// }

// const GalleryImage: FunctionComponent<GalleryImageProps> = ({
//   src,
//   fullWidth = false,
//   ...props
// }) => {
//   const [isLoading, imageLoaded, hasLoadError] = usePreloadedImage(src);

//   if (isLoading || hasLoadError || !imageLoaded) {
//     return <Skeleton className={fullWidth ? "full-width" : ""} />;
//   }
//   return (
//     <ImageGalleryImage
//       className={fullWidth ? "full-width" : ""}
//       {...props}
//       src={src}
//     />
//   );
// };
