import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../core/contexts/AppContext";
import { Colors } from "../../core/colors";
import {
  InfoPanelType,
  useInfoPanelContext,
} from "../../core/contexts/InfoPanelContext";
import { PageBodyGallery, PageBodyType } from "../../pages/types";
import { InfoPanelBase } from "./Base";
import Location from "../Icons/Location.svg";
import GoToSVG from "../Icons/GoTo.svg";
import { InfoPanelGallery } from "./components/InfoGallery";
import { Button } from "../Button";

import ShareSVG from "../Icons/Share.svg";
import { useTranslation } from "react-i18next";
import { useOpenGoogleMaps } from "../../core/hooks/useOpenGoogleMaps";
import { Chevron } from "../Chevron";

const ShareIcon = styled.img.attrs({ src: ShareSVG })`
  height: 32px;
  cursor: pointer;

  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopShareIcon = styled.img.attrs({ src: ShareSVG })`
  height: 32px;
  cursor: pointer;

  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const DragHandleWrapper = styled.div`
  outline: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const DragHandle = styled.div`
  outline: 0;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FirstHalf = styled.div`
  @media (min-width: 768px) {
    background-color: ${Colors.TransparentBlack};
    padding: 25px 30px 20px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(90vh - 180px);
  }
`;

const SecondHalf = styled.div`
  @media (min-width: 768px) {
    background-color: ${Colors.TransparentBlack};
    padding: 15px 30px 30px;
    border-radius: 40px;
    margin-top: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  text-transform: uppercase;
  font-weight: 800;
  color: ${Colors.Cyan};
`;

const PhotosText = styled.div`
  display: flex;
  text-transform: uppercase;
  font-weight: 800;
  color: ${Colors.Cyan};
  font-size: 0.7rem;
`;

const Address = styled.div`
  display: flex;
  font-weight: 800;
  color: ${Colors.White};
  margin-top: 5px;

  span {
    font-size: 0.5rem;
  }
`;

const Desc = styled.div<{ isFullHeight: boolean }>`
  margin-top: 10px;

  max-height: ${({ isFullHeight }) => (isFullHeight ? "200px" : "92px")};

  @media (min-width: 768px) {
    max-height: ${({ isFullHeight }) => (isFullHeight ? "200px" : "56vh")};
    flex: 1;
  }

  overflow: hidden;
  margin-bottom: 10px;
  overflow-y: auto;

  div {
    text-align: justify;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const GoToIcon = styled.img.attrs({ src: GoToSVG })`
  height: 32px;
  cursor: pointer;
  margin-left: 5px;
`;

const LocationIcon = styled.img.attrs({ src: Location })`
  height: 10px;
`;

const defaultMinHeight = 200;

export const InfoPanel: FunctionComponent = () => {
  const { currentLocation, showInfoPanel } = useInfoPanelContext();
  const { locations } = useAppContext();
  const { t } = useTranslation();
  const openGoogleMaps = useOpenGoogleMaps();
  const [height, setHeight] = useState<number>(defaultMinHeight);
  const [isFullHeight, setIsFullHeight] = useState<boolean>(false);

  const [imageGallery, setImageGallery] = useState<PageBodyGallery | null>(
    null
  );

  useEffect(() => {
    const gallery = currentLocation?.body.find(
      ({ type }) => type === PageBodyType.GALLERY
    );
    setImageGallery(gallery as PageBodyGallery);
  }, [currentLocation, locations]);

  const resetHeight = () => {
    setHeight(defaultMinHeight);
    setIsFullHeight(false);
  };

  const handleExpandMinimize = () => {
    if (height > defaultMinHeight) {
      setHeight(defaultMinHeight);
      setIsFullHeight(false);
    } else {
      const maxHeight = window.innerHeight - defaultMinHeight;
      setHeight(maxHeight);
      setIsFullHeight(true);
    }
  };

  return (
    <InfoPanelBase
      padding="15px 30px 30px"
      minHeight={height}
      onClose={resetHeight}
      className="infoPanel"
    >
      <FirstHalf>
        <DragHandleWrapper>
          <DragHandle onClick={handleExpandMinimize}>
            <Chevron rotate={isFullHeight} />
          </DragHandle>
        </DragHandleWrapper>

        <HeaderContainer>
          <div>
            <Header>
              <span>{currentLocation?.title}</span>
            </Header>
            <Address>
              <LocationIcon />
              <span>{currentLocation?.address}</span>
            </Address>
          </div>
          <div>
            <DesktopShareIcon
              onClick={() => showInfoPanel(InfoPanelType.Share)}
            />
          </div>
        </HeaderContainer>
        <Desc isFullHeight={isFullHeight}>
          <div>{currentLocation?.desc}</div>
        </Desc>

        <ButtonSection>
          <Button
            textTransform="uppercase"
            fontSize="0.6rem"
            onClick={() => showInfoPanel(InfoPanelType.MoreInfo)}
          >
            {t("common:actions.Read More")}
          </Button>
          <GoToIcon onClick={() => openGoogleMaps()} />
          <ShareIcon onClick={() => showInfoPanel(InfoPanelType.Share)} />
        </ButtonSection>
      </FirstHalf>

      {currentLocation && imageGallery && (
        <SecondHalf>
          <PhotosText>
            <span>{t("common:Photos")}</span>
          </PhotosText>

          <InfoPanelGallery
            isFullHeight={isFullHeight}
            location={currentLocation}
            images={imageGallery.images}
          />
        </SecondHalf>
      )}
    </InfoPanelBase>
  );
};

InfoPanel.displayName = "InfoPanel";
