import { Config } from "./constants";

export function chunks<T>(arr: T[], chunkSize: number) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function getImageUrl(imageKey: string, size: number = 720) {
  return `${Config.IMAGE_HOST}/images/${size}/${imageKey}`;
}
