import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from "react";
import { FullscreenGallery } from "../../components/FullscreenGallery/FullscreenGallery";
import { PageBodyImage } from "../../pages/types";

export interface GalleryContextType {
  isShownGallery: boolean;
  showGallery: (images: PageBodyImage[], startImageIndex?: number) => void;
  hideGallery: () => void;
}

const defaultContext = {} as GalleryContextType;

const ApplicationContext = createContext<GalleryContextType>(defaultContext);

export const GalleryContext = ApplicationContext.Consumer;

export const useGalleryContext = () => useContext(ApplicationContext);

export const GalleryContextProvider: FunctionComponent = ({ children }) => {
  const [isShownGallery, setIsShownGallery] = useState<boolean>(false);
  const [galleryImages, setGalleryImages] = useState<PageBodyImage[]>([]);
  const [startImageIndex, setStartImageIndex] = useState<number>(0);

  const showGallery = (
    images: PageBodyImage[],
    _startImageIndex: number = 0
  ) => {
    setIsShownGallery(true);
    setGalleryImages(images);
    setStartImageIndex(_startImageIndex);
  };

  const hideGallery = () => {
    setIsShownGallery(false);
  };

  const contextValue: GalleryContextType = {
    isShownGallery,
    showGallery,
    hideGallery,
  };

  return (
    <ApplicationContext.Provider value={contextValue}>
      {isShownGallery && (
        <FullscreenGallery
          images={galleryImages}
          startImageIndex={startImageIndex}
          onClose={hideGallery}
        />
      )}
      {children}
    </ApplicationContext.Provider>
  );
};
