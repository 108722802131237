import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { InfoPanelBase } from "./Base";

import FacebookSVG from "../Icons/Facebook.svg";
import TwitterSVG from "../Icons/Twitter.svg";
import WhatsappSVG from "../Icons/Whatsapp.svg";
import ViberSVG from "../Icons/Viber.svg";
import { useTranslation } from "react-i18next";

import {
  FacebookShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import { Config } from "../../core/constants";
import { useInfoPanelContext } from "../../core/contexts/InfoPanelContext";

const Header = styled.div`
  text-align: center;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const FacebookIcon = styled.img.attrs({ src: FacebookSVG })`
  width: 45px;
`;

const TwitterIcon = styled.img.attrs({ src: TwitterSVG })`
  width: 45px;
`;

const WhatsappIcon = styled.img.attrs({ src: WhatsappSVG })`
  width: 45px;
`;

const ViberIcon = styled.img.attrs({ src: ViberSVG })`
  width: 45px;
`;

export const SharePanel: FunctionComponent = () => {
  const { t } = useTranslation();

  const { currentLocation } = useInfoPanelContext();

  const shareUrl = Config.APP_URL + (currentLocation?.id || "");

  return (
    <InfoPanelBase minHeight={150}>
      <Header>{t("common:Share locations to")}:</Header>
      <Icons>
        <FacebookShareButton
          url={shareUrl}
          hashtag="gljiva"
          quote="gljivardi matadore"
        >
          <FacebookIcon />
        </FacebookShareButton>
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon />
        </WhatsappShareButton>
        <ViberShareButton url={shareUrl}>
          <ViberIcon />
        </ViberShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon />
        </TwitterShareButton>
      </Icons>
    </InfoPanelBase>
  );
};

SharePanel.displayName = "SharePanel";
