import React, { StatelessComponent, FunctionComponent } from "react";
import styled from "styled-components";

export interface LoaderProps {
  /** Color of the dot when it's smallest  */
  primary?: string;
  /** Color of the dot when it's biggest */
  secondary?: string;
  /** Dot color before start of the animation */
  fill?: string;
  /** Test name for automation
   * {name}-{index}-circle - test id of the circle element
   * {name}-{index}-r - test id of the animante element of type r
   * {name}-{index}-fill - test id of the animante element of type fill
   */
  testName?: string;
}

const Svg = styled.svg<React.SVGProps<SVGSVGElement>>`
  display: block;
  margin: auto;
`;

export interface AnimateProps {
  begin: number;
  duration: number;
  colors: string;
  sizes: string;
  testName?: string;
}

const Animate: StatelessComponent<AnimateProps> = ({
  begin,
  duration,
  colors,
  sizes,
  testName,
}) => (
  <>
    <animate
      attributeName="r"
      begin={begin + "s"}
      calcMode="linear"
      data-testid={`${testName}-r`}
      dur={duration + "s"}
      repeatCount="indefinite"
      values={sizes}
    />
    <animate
      attributeName="fill"
      begin={begin + "s"}
      calcMode="linear"
      data-testid={`${testName}-fill`}
      dur={duration + "s"}
      repeatCount="indefinite"
      values={colors}
    />
  </>
);

export const Loader: StatelessComponent<LoaderProps> = ({
  primary = "green",
  secondary = "lightgreen",
  fill = primary,
  testName = "Loader",
}): JSX.Element => {
  const colors = `${primary};${secondary};${primary}`;
  const sizes = `7;9;7`;

  return (
    <Svg
      aria-label="Content Loading..."
      data-testid={testName}
      height="18px"
      role="img"
      viewBox="0 0 76 18"
      width="76px"
    >
      <g>
        <circle
          cx="9"
          cy="9"
          data-testid={`${testName}-1-circle`}
          fill={fill}
          r="7"
        >
          <Animate
            begin={0}
            colors={colors}
            duration={1.4}
            sizes={sizes}
            testName={`${testName}-1`}
          />
        </circle>
        <circle
          cx="37"
          cy="9"
          data-testid={`${testName}-2-circle`}
          fill={fill}
          r="7"
        >
          <Animate
            begin={0.35}
            colors={colors}
            duration={1.4}
            sizes={sizes}
            testName={`${testName}-2`}
          />
        </circle>
        <circle
          cx="67"
          cy="9"
          data-testid={`${testName}-3-circle`}
          fill={fill}
          r="7"
        >
          <Animate
            begin={0.7}
            colors={colors}
            duration={1.4}
            sizes={sizes}
            testName={`${testName}-3`}
          />
        </circle>
      </g>
    </Svg>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const LoaderScreen: FunctionComponent = () => {
  return (
    <Container>
      <Loader />
    </Container>
  );
};

Loader.displayName = "Loader";
