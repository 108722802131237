import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AllLocationsPanel } from "../../components/InfoPanel/AllLocationsPanel";
import { GoToPanel } from "../../components/InfoPanel/GoToPanel";
import { InfoPanel } from "../../components/InfoPanel/InfoPanel";
import { MoreInfoPanel } from "../../components/InfoPanel/MoreInfoPanel";
import { SettingsPanel } from "../../components/InfoPanel/SettingsPanel";
import { SharePanel } from "../../components/InfoPanel/SharePanel";
import { PageProps } from "../../pages/types";
import { replacePathname } from "../utils/replacePathname";
import { useAppContext } from "./AppContext";

export enum InfoPanelType {
  AllLocations = "all-locations",
  GoToLocation = "go-to-location",
  Settings = "settings",
  Share = "share",
  Info = "info",
  MoreInfo = "more-info",
}

export interface InfoPanelContextType {
  isShownInfoPanel: boolean;
  showInfoPanel: (type: InfoPanelType) => void;
  hideInfoPanel: () => void;
  infoPanelType: InfoPanelType | null;
  setInfoPanelType: (type: InfoPanelType) => void;
  showLocationInfoPanel: (id: string) => void;
  showGoToInfoPanel: (id: string) => void;
  currentLocationId: string | null;
  currentLocation: PageProps | null;
}

const defaultContext = {} as InfoPanelContextType;

const ApplicationContext = createContext<InfoPanelContextType>(defaultContext);

export const InfoPanelContext = ApplicationContext.Consumer;

export const useInfoPanelContext = () => useContext(ApplicationContext);

const renderInfoPanel = (type: InfoPanelType | null) => {
  switch (type) {
    case InfoPanelType.AllLocations:
      return <AllLocationsPanel />;
    case InfoPanelType.GoToLocation:
      return <GoToPanel />;
    case InfoPanelType.Share:
      return <SharePanel />;
    case InfoPanelType.Settings:
      return <SettingsPanel />;
    case InfoPanelType.Info:
      return <InfoPanel />;
    case InfoPanelType.MoreInfo:
      return <MoreInfoPanel />;
    default:
      return null;
  }
};

export const InfoPanelContextProvider: FunctionComponent = ({ children }) => {
  const [isShownInfoPanel, setIsShownInfoPanel] = useState<boolean>(false);
  const [infoPanelType, _setInfoPanelType] = useState<InfoPanelType | null>(
    null
  );
  const [currentLocationId, _setLocationId] = useState<string | null>(null);
  const [currentLocation, setCurrentLocation] = useState<PageProps | null>(
    null
  );

  const { locations } = useAppContext();

  const showLocationInfoPanel = useCallback(
    (id: string) => {
      setInfoPanelType(InfoPanelType.Info);
      _setLocationId(id);
      const targetLocation = locations.find((location) => location.id === id);
      setCurrentLocation(targetLocation || null);
      setIsShownInfoPanel(true);
    },
    [locations]
  );

  const showGoToInfoPanel = useCallback(
    (id: string) => {
      setInfoPanelType(InfoPanelType.GoToLocation);
      _setLocationId(id);
      const targetLocation = locations.find((location) => location.id === id);
      setCurrentLocation(targetLocation || null);
      setIsShownInfoPanel(true);
    },
    [locations]
  );

  useEffect(() => {
    const id = window.location.pathname.slice(1);

    if (id) {
      const targetLocation = locations.find((location) => location.id === id);
      if (targetLocation) {
        showLocationInfoPanel(targetLocation.id);
      }
    }
  }, [locations, showLocationInfoPanel]);

  useEffect(() => {
    if (currentLocation?.title) {
      document.title = `Pag online - ${currentLocation.title}`;
      replacePathname(`/${currentLocation.id}`);
    } else {
      document.title = "Pag online";
      // replacePathname("/");
    }
  }, [currentLocation]);

  const showInfoPanel = (type: InfoPanelType) => {
    setIsShownInfoPanel(true);
    setInfoPanelType(type);
  };

  const hideInfoPanel = () => {
    setIsShownInfoPanel(false);
    document.title = "Pag online";
    replacePathname("/");
  };

  const setInfoPanelType = (type: InfoPanelType) => {
    _setInfoPanelType(type);
  };

  const contextValue: InfoPanelContextType = {
    isShownInfoPanel,
    showInfoPanel,
    hideInfoPanel,
    infoPanelType,
    setInfoPanelType,
    showLocationInfoPanel,
    currentLocationId,
    currentLocation,
    showGoToInfoPanel,
  };

  return (
    <ApplicationContext.Provider value={contextValue}>
      {renderInfoPanel(infoPanelType)}
      {children}
    </ApplicationContext.Provider>
  );
};
