import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Router from "./Router";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

import { LoaderScreen } from "./components/Loader";
import { AppContextProvider } from "./core/contexts/AppContext";
import { InfoPanelContextProvider } from "./core/contexts/InfoPanelContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { GalleryContextProvider } from "./core/contexts/GalleryContext";
import { Toaster } from "react-hot-toast";
import { CookieContextProvider } from "./core/contexts/CookieContext";

// Global react-query configs
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

ReactDOM.render(
  <Suspense fallback={<LoaderScreen />}>
    <>
      <QueryClientProvider client={queryClient}>
        <CookieContextProvider>
          <AppContextProvider>
            <GalleryContextProvider>
              <InfoPanelContextProvider>
                <Router />
              </InfoPanelContextProvider>
            </GalleryContextProvider>
          </AppContextProvider>
        </CookieContextProvider>
      </QueryClientProvider>
      <Toaster />
    </>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
