import React, { FunctionComponent } from "react";
import { useAppContext } from "../../core/contexts/AppContext";
import { InfoPanelBase } from "./Base";
import styled from "styled-components";

import GoTo from "../Icons/GoTo.svg";
import { useTranslation } from "react-i18next";
import { Colors } from "../../core/colors";
import { useInfoPanelContext } from "../../core/contexts/InfoPanelContext";

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 800;
  color: ${Colors.Cyan};
  margin: 15px 0 30px;
`;

const Location = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: center;
`;

const LocationTitle = styled.span`
  flex: 1;
`;

const GoToIcon = styled.img.attrs({ src: GoTo })`
  width: 40px;
  cursor: pointer;
`;

export const AllLocationsPanel: FunctionComponent = () => {
  const { locations } = useAppContext();
  const { showGoToInfoPanel } = useInfoPanelContext();

  const { t } = useTranslation();

  return (
    <InfoPanelBase>
      <Header>
        <span>{t("common:Locations in Pag")}</span>
      </Header>
      {locations.map(({ id, title }) => (
        <Location key={id}>
          <LocationTitle>{title}</LocationTitle>
          <GoToIcon onClick={() => showGoToInfoPanel(id)} />
        </Location>
      ))}
    </InfoPanelBase>
  );
};

AllLocationsPanel.displayName = "AllLocationsPanel";
