import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Loader } from "../../components/Loader";
import { MyLocation, PageProps } from "../../pages/types";
import { Config } from "../constants";
import { useApi } from "../hooks/useApi";
// import { useTranslation } from "react-i18next";

const LoaderWrapper = styled.div`
  height: 100vh;
  display: flex;
`;

export enum Language {
  Eng = "en",
  Cro = "hr",
}

export interface AppContextType {
  lang: Language;
  switchLanguage: (lang?: Language) => Language;
  locations: PageProps[];
  myLocation: MyLocation | null;
  setMyLocation: (location: MyLocation) => void;
}

const defaultContext = { lang: Language.Eng } as AppContextType;

const ApplicationContext = createContext<AppContextType>(defaultContext);

export const AppContext = ApplicationContext.Consumer;

export const useAppContext = () => useContext(ApplicationContext);

export const AppContextProvider: FunctionComponent = ({ children }) => {
  const [language, setLanguage] = useState<Language>(Language.Cro);
  // const [locations, setLocations] = useState<PageProps[]>([]);
  const [allLocations, setAllLocations] = useState<PageProps[]>([]);
  const { i18n } = useTranslation();

  const [myLocation, _setMyLocation] = useState<MyLocation | null>(null);

  const { data, error, isFetching } = useApi<PageProps[]>(
    Config.API_HOST,
    "all-data"
  );

  const locations = useMemo(
    () => allLocations.filter(({ lang }) => lang === language),
    [allLocations, language]
  );

  useEffect(() => {
    try {
      // eslint-disable-next-line no-restricted-globals
      screen.orientation.lock("portrait");
    } catch {}
  }, []);

  useEffect(() => {
    if (!isFetching && data && !error) {
      const _locations = data as unknown as PageProps[];

      setAllLocations(_locations);

      const id = window.location.pathname.slice(1);

      if (id) {
        const targetLocation = _locations.find(
          (location) => location.id === id
        );
        if (targetLocation) {
          setMyLocation({
            address: targetLocation.address,
            location: targetLocation.location,
          });
        }
      }
    }
  }, [data, error, isFetching]);

  const switchLanguage = (lang?: Language) => {
    const nextLanguage =
      lang || language === Language.Eng ? Language.Cro : Language.Eng;
    setLanguage(nextLanguage);
    i18n.changeLanguage(nextLanguage);

    return nextLanguage;
  };

  const setMyLocation = (location: MyLocation) => {
    _setMyLocation(location);
  };

  const contextValue: AppContextType = {
    lang: language,
    switchLanguage,
    locations,
    myLocation,
    setMyLocation,
  };

  if (isFetching) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
    </ApplicationContext.Provider>
  );
};
