import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Colors } from "../../core/colors";
import { useOnClickOutside } from "../../core/hooks/useOnClickOutside";
import { getImageUrl } from "../../core/utils";
import { PageBodyImage } from "../../pages/types";
import ChevronLeftSVG from "../Icons/ChevronLeft.svg";
import ChevronRightSVG from "../Icons/ChevronRight.svg";
import CloseSVG from "../Icons/Close.svg";

const Wrapper = styled.div`
  background-color: ${Colors.TransparentBlack};
  color: ${Colors.White};
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 20;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;
  height: 100%;
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ImageInnerContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
`;

const ControlContainer = styled.div`
  position: absolute;
  top: calc(50% - 15px);
  width: 90%;
  min-width: 200px;
  left: 5%;
  display: flex;
  justify-content: space-between;
  height: 30px;
`;

const ChevronLeftIcon = styled.img.attrs({ src: ChevronLeftSVG })`
  height: 32px;
  cursor: pointer;
  margin-left: 7px;
`;

const ChevronRightIcon = styled.img.attrs({ src: ChevronRightSVG })`
  height: 32px;
  cursor: pointer;
  margin-right: 2px;
`;

const CloseIcon = styled.img.attrs({ src: CloseSVG })`
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 30px;
`;

const SingleImage = styled.img`
  max-height: 90vh;
  max-width: 90vw;
  border-radius: 35px;
`;

export interface MoreInfoPanelProps {
  onClose?: () => void;
  images: PageBodyImage[];
  startImageIndex: number;
}

export const FullscreenGallery: FunctionComponent<MoreInfoPanelProps> = ({
  onClose,
  images,
  startImageIndex,
}) => {
  const [currentImageIndex, setCurrentImageIndex] =
    useState<number>(startImageIndex);
  const [loaded, setLoaded] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (
      currentImageIndex + 1 < images.length &&
      !loaded[images[currentImageIndex + 1].key]
    ) {
      const img = new Image();
      img.src = getImageUrl(images[currentImageIndex + 1].key, 1140);
      setLoaded({ ...loaded, [images[currentImageIndex + 1].key]: true });
    }
  }, [currentImageIndex, images, loaded]);

  const handleNext = () => {
    if (currentImageIndex + 1 < images.length) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      setCurrentImageIndex(0);
    }
  };

  const handlePrev = () => {
    if (currentImageIndex - 1 > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else {
      setCurrentImageIndex(images.length - 1);
    }
  };

  const clickOutsideRef = useRef(null);
  useOnClickOutside(clickOutsideRef, () => {
    setTimeout(() => {
      onClose && onClose();
    }, 500);
  });

  return (
    <Wrapper ref={clickOutsideRef}>
      <Container>
        <ImageContainer>
          <ImageInnerContainer>
            <SingleImage
              src={getImageUrl(images[currentImageIndex].key, 1140)}
              alt={images[currentImageIndex].title}
            />
            <CloseIcon onClick={() => onClose && onClose()} />
            <ControlContainer>
              <ChevronLeftIcon onClick={() => handlePrev()} />
              <ChevronRightIcon onClick={() => handleNext()} />
            </ControlContainer>
          </ImageInnerContainer>
        </ImageContainer>
      </Container>
    </Wrapper>
  );
};

FullscreenGallery.displayName = "FullscreenGallery";
