import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from "react";
import { useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";

export interface CookieContextType {
  hasConsent: boolean;
  revokeConsent: () => boolean;
}

const defaultContext: CookieContextType = {
  hasConsent: false,
  revokeConsent: () => false,
};

const ApplicationContext = createContext<CookieContextType>(defaultContext);

export const CookieContext = ApplicationContext.Consumer;

export const useCookieContext = () => useContext(ApplicationContext);

export const CookieContextProvider: FunctionComponent = ({ children }) => {
  const [hasConsent, setHasConsent] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const grantCookie = Cookies.get("CookieConsentGrantedAt");

    if (grantCookie) {
      setHasConsent(true);
    }
  }, []);

  const grantConsent = () => {
    setHasConsent(true);
    return true;
  };

  const revokeConsent = () => {
    Cookies.remove("CookieConsentGrantedAt");
    setHasConsent(false);
    return true;
  };

  const contextValue: CookieContextType = {
    hasConsent,
    revokeConsent,
  };

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
      <CookieConsent
        location="top"
        buttonText={t("common:cookie.buttons.accept")}
        declineButtonText={t("common:cookie.buttons.decline")}
        cookieName="CookieConsentGrantedAt"
        cookieValue={new Date().getTime().toString()}
        style={{ background: "black" }}
        customButtonWrapperAttributes={{}}
        buttonWrapperClasses="buttons"
        buttonStyle={{
          backgroundColor: "rgb(61, 192, 16)",
          color: "black",
          fontSize: "15px",
          fontWeight: "bold",
        }}
        declineButtonStyle={{
          color: "black",
          fontSize: "15px",
          fontWeight: "bold",
        }}
        expires={150}
        enableDeclineButton
        onAccept={grantConsent}
        onDecline={revokeConsent}
      >
        {t("common:cookie.content")}
      </CookieConsent>
    </ApplicationContext.Provider>
  );
};
