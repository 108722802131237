import styled from "styled-components";

export const Button = styled.button<{
  fontSize?: string;
  textTransform?: string;
}>`
  background: rgb(16, 81, 245);
  background: linear-gradient(
    270deg,
    rgba(16, 81, 245, 1) 0%,
    rgba(90, 233, 207, 1) 100%
  );
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100px;
  min-height: 32px;
  border-radius: 25px;
  outline: 0;
  border: 0;
  color: white;
  font-weight: 800;
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  cursor: pointer;

  ${({ textTransform }) =>
    textTransform ? `text-transform: ${textTransform};` : ""}
`;
