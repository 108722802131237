import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Colors } from "../../core/colors";

import AllSVG from "../Icons/All.svg";
import DesktopAllSVG from "../Icons/DesktopAll.svg";
import LanguageSwitchEngSVG from "../Icons/LanguageSwitch-eng.svg";
import LanguageSwitchCroSVG from "../Icons/LanguageSwitch-cro.svg";
import GoToSVG from "../Icons/GoTo.svg";
import ShareSVG from "../Icons/Share.svg";
import InfoSVG from "../Icons/Info.svg";
import DesktopInfoSVG from "../Icons/DesktopInfo.svg";
import { Language, useAppContext } from "../../core/contexts/AppContext";
import {
  InfoPanelType,
  useInfoPanelContext,
} from "../../core/contexts/InfoPanelContext";

const Wrapper = styled.div`
  z-index: 1000;
  * {
    z-index: 1000;
  }
  background-color: ${Colors.Black};
  color: ${Colors.White};
  height: 9vh;
  min-height: 50px;
  width: 100%;
  display: flex;

  img {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopWrapper = styled.div`
  z-index: 1000;
  * {
    z-index: 1000;
  }
  background-color: ${Colors.Transparent};
  color: ${Colors.White};
  height: 9vh;
  width: 100%;

  img {
    cursor: pointer;
  }

  align-items: center;
  gap: 30px;
  padding: 0 30px;

  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const Aside = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const GoToIcon = styled.img.attrs({ src: GoToSVG })`
  height: 80px;
  position: absolute;
  bottom: 10px;
`;

const AllIcon = styled.img.attrs({ src: AllSVG })`
  width: 32px;
  margin-left: 10px;
`;

const DesktopAllIcon = styled.img.attrs({ src: DesktopAllSVG })`
  width: 32px;
`;

const SwitchIcon = styled.img`
  width: 45px;
  margin-right: 10px;
`;

const DesktopSwitchIcon = styled.img`
  width: 55px;
`;

const ShareIcon = styled.img.attrs({ src: ShareSVG })`
  height: 32px;
`;

const InfoIcon = styled.img.attrs({ src: InfoSVG })`
  height: 32px;
  margin-right: 10px;
`;

const DesktopInfoIcon = styled.img.attrs({ src: DesktopInfoSVG })`
  height: 32px;
`;

export interface ToolbarProps {}

export const MobileToolbar: FunctionComponent<ToolbarProps> = () => {
  const { lang, switchLanguage } = useAppContext();
  const { showInfoPanel } = useInfoPanelContext();

  return (
    <Wrapper>
      <Aside>
        <AllIcon
          alt="All"
          onClick={() => showInfoPanel(InfoPanelType.AllLocations)}
        />
        <SwitchIcon
          alt="Language Switcher"
          src={
            lang === Language.Eng ? LanguageSwitchEngSVG : LanguageSwitchCroSVG
          }
          onClick={() => switchLanguage()}
        />
      </Aside>
      <Center>
        <GoToIcon
          alt="Goto"
          onClick={() => showInfoPanel(InfoPanelType.GoToLocation)}
        />
      </Center>
      <Aside>
        <ShareIcon
          alt="Share"
          onClick={() => {
            showInfoPanel(InfoPanelType.Share);
          }}
        />
        <InfoIcon
          alt="Settings"
          onClick={() => showInfoPanel(InfoPanelType.Settings)}
        />
      </Aside>
    </Wrapper>
  );
};
export const DesktopToolbar: FunctionComponent<ToolbarProps> = () => {
  const { lang, switchLanguage } = useAppContext();
  const { showInfoPanel } = useInfoPanelContext();

  return (
    <DesktopWrapper>
      <DesktopSwitchIcon
        alt="Language Switcher"
        src={
          lang === Language.Eng ? LanguageSwitchEngSVG : LanguageSwitchCroSVG
        }
        onClick={() => switchLanguage()}
      />
      <DesktopInfoIcon
        alt="Settings"
        onClick={() => showInfoPanel(InfoPanelType.Settings)}
      />
      <DesktopAllIcon
        alt="All"
        onClick={() => showInfoPanel(InfoPanelType.AllLocations)}
      />
    </DesktopWrapper>
  );
};

export const Toolbar: FunctionComponent<ToolbarProps> = () => {
  // disclaimer/todo easy fix because I'm running out of time (1 day left :see-no-evil:)

  return (
    <>
      <MobileToolbar />
      <DesktopToolbar />
    </>
  );
};

Toolbar.displayName = "Toolbar";
