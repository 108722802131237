import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Colors } from "../../core/colors";
import {
  InfoPanelType,
  useInfoPanelContext,
} from "../../core/contexts/InfoPanelContext";
import ShareSVG from "../Icons/Share.svg";
import LocationSVG from "../Icons/Location.svg";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { PageBodyType } from "../../pages/types";
import { getImageUrl } from "../../core/utils";

const Wrapper = styled.div<{ show?: boolean }>`
  background-color: ${Colors.TransparentBlack};
  color: ${Colors.White};
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 20;

  @media (min-width: 768px) {
    width: 90%;
    height: 90%;
    left: calc(50% - 400px);
    top: 5%;
    border-radius: 40px;
    padding: 15px 20px 0px;
    max-width: 800px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  padding: 20px 30px;
`;

const Header = styled.div`
  display: flex;
  text-transform: uppercase;
  font-weight: 800;
  color: ${Colors.Cyan};
`;

const Address = styled.div`
  display: flex;
  font-weight: 800;
  color: ${Colors.White};
  margin-top: 5px;

  span {
    font-size: 0.5rem;
  }
`;

const DescContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1;
  overflow-y: auto;
`;

const Desc = styled.div`
  margin-bottom: 10px;
  text-align: justify;
`;

const DescImg = styled.img`
  margin-bottom: 10px;
  width: 100%;
`;

const ButtonSection = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const LocationIcon = styled.img.attrs({ src: LocationSVG })`
  height: 10px;
`;

const ShareIcon = styled.img.attrs({ src: ShareSVG })`
  height: 32px;
  cursor: pointer;
`;

export interface MoreInfoPanelProps {
  onClose?: () => void;
}

export const MoreInfoPanel: FunctionComponent<MoreInfoPanelProps> = ({
  onClose,
}) => {
  const { isShownInfoPanel, showInfoPanel, currentLocation } =
    useInfoPanelContext();
  const { t } = useTranslation();

  return (
    <Wrapper show={isShownInfoPanel}>
      <Container>
        <Header>
          <span>{currentLocation?.title}</span>
        </Header>
        <Address>
          <LocationIcon />
          <span>{currentLocation?.address}</span>
        </Address>
        <DescContainer>
          {currentLocation?.body.map((row, i) => {
            if (row.type === PageBodyType.STRING) {
              return (
                <Desc
                  key={i}
                  dangerouslySetInnerHTML={{ __html: row.text || "" }}
                ></Desc>
              );
            } else if (row.type === PageBodyType.IMG && row?.img?.key) {
              return (
                <DescImg
                  key={i}
                  alt={row?.img?.title}
                  src={getImageUrl(row.img.key)}
                />
              );
            }
          })}
        </DescContainer>

        <ButtonSection>
          <Button
            textTransform="uppercase"
            fontSize="0.6rem"
            onClick={() => showInfoPanel(InfoPanelType.Info)}
          >
            {"< "}
            {t("common:actions.Back")}
          </Button>
          <ShareIcon onClick={() => showInfoPanel(InfoPanelType.Share)} />
        </ButtonSection>
      </Container>
    </Wrapper>
  );
};

MoreInfoPanel.displayName = "MoreInfoPanel";
