import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ReactGA from "react-ga4";
import { Map } from "../components/Map/Map";
import { useParams } from "react-router-dom";

import { Toolbar } from "../components/Toolbar/Toolbar";
import styled from "styled-components";
import { useCookieContext } from "../core/contexts/CookieContext";

const MapContainer = styled.div`
  display: flex;
  height: 91vh;

  @media (min-width: 768px) {
    height: 100vh;
  }
`;

const ToolbarContainer = styled.div`
  display: flex;
  z-index: 10;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 9vh;
`;

export const SinglePage: FunctionComponent = () => {
  const { id } = useParams<{ id?: string }>();
  const { hasConsent } = useCookieContext();

  useEffect(() => {
    if (hasConsent && id) {
      ReactGA.event({
        category: "QR Code scanned",
        action: id,
      });
    }
  }, [hasConsent, id]);

  return (
    <Grid container direction="column">
      <MapContainer>
        <Map />
      </MapContainer>
      <ToolbarContainer>
        <Toolbar />
      </ToolbarContainer>
    </Grid>
  );
};
