import { Language } from "../core/contexts/AppContext";

export enum PageBodyType {
  STRING = "STRING",
  IMG = "IMG",
  GALLERY = "GALLERY",
}

export interface PageBodyImage {
  title: string;
  subtitle: string;
  key: string;
}

export interface PageBodyGallery {
  type: PageBodyType;
  images: PageBodyImage[];
}
export interface PageBody {
  type: PageBodyType;
  text?: string;
  key?: string;
  images?: PageBodyImage[];
  img?: PageBodyImage;
}

export enum MarkerType {
  CHURCH = "church",
  FORT = "fort",
  PALACE = "palace",
  MONASTERY = "monastery",
  SQUARE = "square",
  INFO = "info",
  SCROLL = "scroll",
}

export interface MyLocation {
  address: string;
  location: GeoLocation;
}

export interface GeoLocation {
  lat: number;
  lng: number;
}

export interface PageProps {
  id: string;
  title: string;
  lang: Language;
  address: string;
  location: GeoLocation;
  desc: string;
  marker: MarkerType;
  subtitle: string;
  header: string;
  body: PageBody[];
  flipMarker?: boolean;
}

export interface Page {
  [id: string]: PageProps;
}
