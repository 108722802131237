import React, { FunctionComponent } from "react";
import { Colors } from "../../core/colors";
import { InfoPanelBase } from "./Base";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 800;
  color: ${Colors.Cyan};
  margin: 15px 0 30px;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  a {
    color: ${Colors.White};
  }
`;

export const SettingsPanel: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  return (
    <InfoPanelBase>
      <Header>
        <span>{t("common:Created By")}</span>
      </Header>
      <Text>
        <a
          href={"http://tzgpag.hr/" + i18n.language}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("common:TZG")}
        </a>
      </Text>
      <Text>
        <span>&</span>
      </Text>
      <Text>
        <a href="https://800.hr" target="_blank" rel="noopener noreferrer">
          {t("common:800")}
        </a>
      </Text>
    </InfoPanelBase>
  );
};

SettingsPanel.displayName = "SettingsPanel";
