import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  CancelToken,
  ResponseType,
} from "axios";

export interface Options {
  /**
   * Uses `any` instead of `unknown` because this won't accept interfaces otherwise.
   *
   * @see https://github.com/microsoft/TypeScript/issues/15300#issuecomment-702872440
   */
  body?: Record<string, any>;
  headers?: AxiosRequestHeaders;
  data?: any;
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD";
  noAuth?: boolean;
  params?: any;
  cancelToken?: CancelToken;
  hide4xxErrorNotifications?: boolean;
  responseType?: ResponseType;
}

export const defaultOptions: Options = {
  body: {},
  method: "GET",
  noAuth: false,
  hide4xxErrorNotifications: false,
};

interface ResponseInterface<Data = any> {
  data: Data;
  status: number;
  code: string;
  statusText: string;
  headers: Record<string, unknown>;
  config: any;
}

// Note: Passing in a falsy queryKey will prevent the query from running.
export function useApi<R>(
  path: string,
  queryKey: QueryKey,
  options?: Options,
  useQueryOptions: UseQueryOptions<ResponseInterface<R>> = {}
) {
  return useQuery(
    queryKey,
    () =>
      apiCall<ResponseInterface<R>>(path, { ...(options || defaultOptions) }),
    useQueryOptions
  );
}

export function apiCall<R>(path: string, options: Options = defaultOptions) {
  return api<R>(path, options)
    .then((data) => data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function api<R>(
  path: string,
  {
    method,
    body,
    data,
    headers = {},
    params,
    cancelToken,
    responseType,
  }: Options
) {
  const axiosRequestConfig: AxiosRequestConfig = {
    url: path,
    method,
    data:
      data ||
      (method !== "GET" && {
        ...body,
      }),
    params,
    headers,
  };
  if (cancelToken) {
    axiosRequestConfig["cancelToken"] = cancelToken;
  }
  if (responseType) {
    axiosRequestConfig["responseType"] = responseType;
  }
  return axios.request<R>(axiosRequestConfig);
}
