import { MarkerType } from "../../pages/types";

export const getIcon = (
  type?: MarkerType,
  isActive: boolean = false,
  isFlipped: boolean = false
) => {
  const state = isActive ? "Active" : "Inactive";
  const flippedState = isFlipped ? "_Flipped" : "";

  switch (type) {
    case MarkerType.CHURCH:
      return `/images/markers/Crkva_${state}${flippedState}.png`;
    case MarkerType.FORT:
      return `/images/markers/Kula_${state}${flippedState}.png`;
    case MarkerType.PALACE:
      return `/images/markers/Dvor_${state}${flippedState}.png`;
    case MarkerType.MONASTERY:
      return `/images/markers/Samostan_${state}${flippedState}.png`;
    case MarkerType.SQUARE:
      return `/images/markers/Trg_${state}${flippedState}.png`;
    case MarkerType.SCROLL:
      return `/images/markers/Povelja_${state}${flippedState}.png`;
    default:
      return `/images/markers/Povelja_${state}${flippedState}.png`;
  }
};
