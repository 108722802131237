import React, { FunctionComponent, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { silverMap } from "./style";
import { Marker } from "./Marker";
import { useAppContext } from "../../core/contexts/AppContext";
import { PageProps } from "../../pages/types";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const mapCenter = {
  lat: 44.443768,
  lng: 15.054497,
  zoom: 17,
};
const API_KEY = "AIzaSyAGtABPO1RoHqv2u7hmPCsRn1YX5stJwWA";

// @ts-ignore
window.initMap = () => {};

export const Map: FunctionComponent = () => {
  const { locations, lang } = useAppContext();

  const [markers, setMarkers] = useState<PageProps[]>([]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  const [, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(17);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (locations) {
      const googleMarkers = [];

      for (let i = 0; i < locations.length; i++) {
        googleMarkers.push(locations[i]);
      }

      setMarkers(googleMarkers);
    }
  }, [locations, lang]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter}
      options={{
        styles: silverMap,
        disableDefaultUI: true,
        mapTypeId: "roadmap",
        draggable: true,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        gestureHandling: "greedy",
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {markers.map((location) => {
        return <Marker key={location.id} {...location} />;
      })}
    </GoogleMap>
  ) : (
    <></>
  );
};
