import React, {
  FunctionComponent,
  ReactNode,
  // useEffect,
  useRef,
} from "react";
import { Marker as BaseMarker } from "@react-google-maps/api";
import { getIcon } from "./util";
import { PageProps } from "../../pages/types";
import {
  InfoPanelType,
  useInfoPanelContext,
} from "../../core/contexts/InfoPanelContext";
// import { useAppContext } from "../../core/contexts/AppContext";

export interface MarkerProps extends PageProps {
  additional?: ReactNode;
  onOpen?: (id: string | number) => void;
}

export const Marker: FunctionComponent<MarkerProps> = ({
  id,
  location: { lat, lng },
  title,
  marker,
  flipMarker,
  onOpen,
}) => {
  const {
    showLocationInfoPanel,
    currentLocationId,
    isShownInfoPanel,
    infoPanelType,
  } = useInfoPanelContext();
  // const { lang: appLang } = useAppContext();

  const markerRef = useRef<BaseMarker>(null);

  // useEffect(() => {
  //   if (lang !== appLang) {
  //     console.log("clear marker", markerRef.current);
  //     @ts-ignore
  //     markerRef.current?.setMap?.(null);
  //     @ts-ignore
  //     markerRef.current.props.onClick();
  //   }
  // }, [appLang]);

  // useEffect(() => {
  //   return () => {
  //     @ts-ignore
  //     markerRef.current?.setMap?.(null);
  //     const rawMarkers = document.querySelectorAll(`div[title="${title}"]`);
  //     console.log("rawMarkers", rawMarkers);
  //     for (let i = 0; i < rawMarkers?.length; i++) {
  //     rawMarkers[i].remove();
  //     }
  //   };
  // });

  const handleOpen = () => {
    onOpen && onOpen(id);
    showLocationInfoPanel(id);
  };

  const isActive =
    id === currentLocationId &&
    isShownInfoPanel &&
    infoPanelType === InfoPanelType.Info;

  return (
    <BaseMarker
      ref={markerRef}
      position={{ lat, lng }}
      onClick={handleOpen}
      title={title}
      // visible={appLang === Language.Cro}
      // opacity={appLang === Language.Eng ? 1 : 0}
      icon={{
        url: getIcon(marker, isActive, !!flipMarker),
        scaledSize: new google.maps.Size(32, 32),
        anchor: !!flipMarker
          ? new google.maps.Point(30, 0)
          : new google.maps.Point(0, 30),
        rotation: 300,
      }}
    ></BaseMarker>
  );
};

Marker.displayName = "Marker";
