import React, { FunctionComponent, useCallback, useRef } from "react";
import styled, { keyframes, css } from "styled-components";

import { Colors } from "../../core/colors";
import { useOnClickOutside } from "../../core/hooks/useOnClickOutside";
import { useInfoPanelContext } from "../../core/contexts/InfoPanelContext";
import { useGalleryContext } from "../../core/contexts/GalleryContext";

const bounceUpAnimation = keyframes`
 0% { bottom: -100%; }
 100% { bottom: 15%; }
`;

const bounceDownAnimation = keyframes`
 0% { bottom: 15%; }
 100% { bottom: -100%; }
`;

const desktopBounceUpAnimation = keyframes`
 0% { bottom: -100%; }
 100% { bottom: 15%; }
`;

const desktopBounceDownAnimation = keyframes`
 0% { bottom: 15%; }
 100% { bottom: -100%; }
`;

const bounceUpFromTopAnimation = keyframes`
 0% { top: -100%; }
 100% { top: 5%; }
`;

const bounceDownFromTopAnimation = keyframes`
 0% { top: 5%; }
 100% { top: -100%; }
`;

const Wrapper = styled.div<{
  show?: boolean;
  minHeight?: number;
  isInfoPanel: boolean;
}>`
  background-color: ${Colors.TransparentBlack};
  border-radius: 40px;
  color: ${Colors.White};
  position: absolute;
  bottom: -100%;
  min-height: ${({ minHeight }) => minHeight}px;
  max-height: 80%;
  transition: min-height 0.2s ease-in-out;
  width: 90%;
  left: 5%;
  z-index: 10;
  overflow-y: auto;

  animation: ${({ show }) => (show ? bounceUpAnimation : bounceDownAnimation)};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  @media (min-width: 768px) {
    max-width: 400px;
    left: calc(50% - 200px);

    animation: ${({ show }) =>
      show ? desktopBounceUpAnimation : desktopBounceDownAnimation};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    ${({ isInfoPanel, show }) =>
      isInfoPanel
        ? css`
            background-color: ${Colors.Transparent};
            max-height: 90%;

            left: auto;
            right: 5%;

            animation: ${show
              ? bounceUpFromTopAnimation
              : bounceDownFromTopAnimation};
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          `
        : ""}
  }
`;

const Container = styled.div<{ padding: string; isInfoPanel: boolean }>`
  padding: ${({ padding }) => padding};

  @media (min-width: 768px) {
    ${({ isInfoPanel }) =>
      isInfoPanel
        ? css`
            padding: unset;
          `
        : ""}
  }
`;

export interface InfoPanelBaseProps {
  minHeight?: number;
  padding?: string;
  className?: string;
  onClose?: () => void;
}

export const InfoPanelBase: FunctionComponent<InfoPanelBaseProps> = ({
  children,
  minHeight = 200,
  padding = "30px",
  className,
  onClose,
}) => {
  const { isShownInfoPanel, hideInfoPanel } = useInfoPanelContext();
  const { isShownGallery } = useGalleryContext();
  const clickOutsideRef = useRef(null);

  const handleClickOutside = useCallback(() => {
    if (!isShownGallery && isShownInfoPanel) {
      hideInfoPanel();
      onClose && onClose();
    }
  }, [isShownGallery, isShownInfoPanel, hideInfoPanel, onClose]);

  useOnClickOutside(clickOutsideRef, handleClickOutside);

  return (
    <Wrapper
      isInfoPanel={className === "infoPanel"}
      ref={clickOutsideRef}
      show={isShownInfoPanel}
      minHeight={minHeight}
    >
      <Container isInfoPanel={className === "infoPanel"} padding={padding}>
        {children}
      </Container>
    </Wrapper>
  );
};

InfoPanelBase.displayName = "InfoPanelBase";
