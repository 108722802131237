import React, { FunctionComponent } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { SinglePage } from "./pages/SingleMobile";
import { useCookieContext } from "./core/contexts/CookieContext";
import { useEffect } from "react";

const trackingId = "G-G53SZXE773";

const App: FunctionComponent = () => {
  // return <div style={{
  //   backgroundColor: "black",
  //   height: '100vh',
  //   color: "yellow",
  //   display:"flex",
  //   justifyContent: "center",
  //   alignItems:"center"
  // }}><strong>Coming soon in towns near you!</strong></div>

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<SinglePage />} />
        <Route path="/" element={<SinglePage />} />
      </Routes>
      <Tracking />
    </BrowserRouter>
  );
};

export default App;

const Tracking = () => {
  const { hasConsent } = useCookieContext();

  let location = useLocation();

  useEffect(() => {
    if (hasConsent) {
      ReactGA.initialize(trackingId);

      ReactGA.set({ page: location.pathname });
      ReactGA.event({
        category: "Page View",
        action: location.pathname,
      });
    }
  }, [hasConsent, location]);

  return null;
};
