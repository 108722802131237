import { useCallback } from "react";
import { useAppContext } from "../contexts/AppContext";
import { useInfoPanelContext } from "../contexts/InfoPanelContext";
import ReactGA from "react-ga4";
import { useCookieContext } from "../contexts/CookieContext";

export const useOpenGoogleMaps = () => {
  const { myLocation, locations } = useAppContext();
  const { currentLocation } = useInfoPanelContext();
  const { hasConsent } = useCookieContext();

  return useCallback(() => {
    if (hasConsent) {
      ReactGA.event({
        category: "User",
        action: "Launched Google Maps",
      });
    }
    if (myLocation?.address || myLocation?.location?.lat) {
      const origin = myLocation?.location.lat
        ? myLocation?.location.lat + "," + myLocation?.location.lng
        : myLocation?.address || "";
      const destination = currentLocation?.location?.lat
        ? (currentLocation?.location?.lat || locations?.[0]?.location?.lat) +
          "," +
          (currentLocation?.location?.lng || locations?.[0]?.location?.lng)
        : locations?.[0]?.address || "";

      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=walking`,
        "_blank"
      );
    } else {
      if (
        currentLocation?.location?.lat ||
        (!!locations?.[0]?.location?.lat && !locations?.[0]?.address)
      ) {
        window.open(
          "https://maps.google.com?q=" +
            (currentLocation?.location?.lat || locations?.[0]?.location?.lat) +
            "," +
            (currentLocation?.location?.lng || locations?.[0]?.location?.lng),
          "_blank"
        );
      } else {
        window.open(
          "https://maps.google.com?q=" +
            locations?.[0]?.address +
            " Grad Pag" || "Grad Pag",
          "_blank"
        );
      }
    }
  }, [myLocation, currentLocation, locations, hasConsent]);
};
